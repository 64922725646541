import React, { useState } from "react";
import { graphql } from "gatsby";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Link } from "gatsby-theme-material-ui";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import BetaHero from "../../components/betahero/betahero";
import SEO from "../../components/seo";

const Bold = ({ children }) => <span className="bold">{children}</span>;
const Text = ({ children }) => <p className="align-center">{children}</p>;
const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
};

const IndexPage = (props) => {
  return (
    <React.Fragment>
      <SEO title="Home"/>
      <BetaHero />

      <section className="content">
        <Container maxWidth="sm">
          <Box>
            <Typography component="h2" variant="overline">
              Recent Journeys
            </Typography>
          </Box>
          {props.data.allContentfulTrip.edges.map((edge) => (
            <article>
              <Box className="content-item" key={edge.node.id}>
                <Box className="user-avatar">
                  <Link to={`/profile/${edge.node.author.slug}`}>
                    {edge.node.author.profilePhoto ? (
                      <img src={edge.node.author.profilePhoto.file.url} />
                    ) : (
                      <img
                        src="https://images.ctfassets.net/iyqxhlk14jb8/4zo0lWV0x7EKtTrypYAIay/2435a984ffc7013b3484521a080f7e77/default-avatar.png"
                        alt="user avatar"
                      />
                    )}
                  </Link>
                </Box>
                <Box className="content-item-info">
                  <Box className="content-item-meta">
                    <Box component="span">
                      <Typography variant="body1">
                        <Link
                          to={`/profile/${edge.node.author.slug}`}
                          className="author-name"
                        >
                          {edge.node.author.name}
                        </Link>
                      </Typography>
                    </Box>
                    <Box component="span">
                      {edge.node.category && (
                        <div>
                          <Box component="span" className="separator">
                            &#183;
                          </Box>
                          <Box component="span">{edge.node.category} </Box>
                        </div>
                      )}
                    </Box>
                    <Box component="span">
                      {edge.node.level && (
                        <div>
                          <Box component="span" className="separator">
                            &#183;
                          </Box>
                          <Box component="span">{edge.node.level}</Box>
                        </div>
                      )}
                    </Box>
                  </Box>
                  <Box className="content-item-title">
                    <Typography variant="body1" component="h3">
                      <Link
                        to={`/app/unpack/${edge.node.slug}`}
                        color="secondary"
                      >
                        {edge.node.title}
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </article>
          ))}
        </Container>
      </section>
    </React.Fragment>
  );
};

export default IndexPage;

export const query = graphql`
  {
    allContentfulTrip(sort: { fields: updatedAt, order: DESC }) {
      edges {
        node {
          id
          title
          slug
          author {
            id
            slug
            name
            profilePhoto {
              title
              file {
                url
              }
              resize(height: 10, width: 10) {
                src
              }
            }
          }
          level
          category
          summary {
            json
          }
          updatedAt
        }
      }
    }
  }
`;
