import React, { useState } from "react";
import { Link } from "gatsby-theme-material-ui";
import { Box, Container, Grid, Typography } from "@material-ui/core";

import betaheroimage from "../../images/beta-hero-graphic.png";
import "./betahero.scss";

function BetaHero() {
  return (
    <Box className="beta-hero">
      <Container maxWidth="sm">
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={9}>
            <Typography variant="h1" gutterBottom>
              A platform for self-learners to unpack their learning journeys
            </Typography>
          </Grid>
          <Grid item xs={3}>
              <img src={betaheroimage} alt="" aria-hidden="true" />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default BetaHero;
